@import './colors.scss', './mixins.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss"; 

* {
    box-sizing: border-box;
    overscroll-behavior: none;
}

html {
    width: 100vw;
    overflow-x: hidden;
}

h1 {
  @include heading(32px);
}

h2 {
  @include heading(28px);
}

h3 {
  @include heading(24px);
}

h4 {
  @include heading(20px);
  color: white;
}

p {
  @include text;
}

a {
    text-decoration: none;
    color: $blue;
    font-weight: 400;

    &:hover {
        font-weight: 700;
    }
    
    &:visited {
        color: $blue;
    }
}

.btn {
  padding: 0 20px;
  background-color: $orange;
  color: white;
  border: none;
  border-radius: 20px;
  font-family: 'Mandali', sans-serif;
  transition: .7s;
  font-size: 16px;

  &:hover {
    background-color: $blue;
    transform: translateY(-5px);
  }

  &:active {
    transition: .3s;
    transform: translateY(-2px);
  }

  &:disabled,
  &[disabled] {
    background-color: $grey;
    transform: none;
  }
}

.app {
    @include flex(column, center);
}

.header-container {
  height: 95px;
  width:100vw;
  @include flex(row, center);
  background-color: $grey;
  font-family: 'Mandali', sans-serif;
  z-index: 5;
  
  .header {
    width: 100vw;
    @include flex(row, space-between);
    padding: 0 20px;

    img {
      height: 75px;
    }

    p {
        color: $blue;
    }

    nav {
        transition: .7s;
        position: absolute;
        top: 95px;
        @include flex(column, space-between);
        z-index: 5;
        background-color: $orange;
        width: 100vw;
        height: 200px;
        padding: 25px 0;

        .link {
          cursor: pointer;
        }
    }

    .closed {
        opacity: 0;
        right: -200px;
    }

    .open {
        opacity: 1;
        right: 0px;
    }
  }
}

.main-nav-container {
  width: 100vw;
  @include flex(row, center);
  background-color: $blue;
  height: 60px;

  nav {
    cursor: pointer;
    width: 200px;
    @include flex(row, space-between);
    
    a {
      font-family: 'Mandali', sans-serif;
      text-decoration: none;
      color: white;
      font-weight: 400;
  
      &:hover {
          font-weight: 700;
      }
      
      &:visited {
          color: white;
      }
  }
  }
}


.hero {
  width: 100vw;
  font-family: 'Mandali', sans-serif;

  .image-gallery {
    width: 100vw;
    margin: 0;
  }

  .image-gallery-slide img {
    height: 250px;
  }
}

.text-container {
  width: 100vw;
  font-family: 'Mandali', sans-serif;
  background-color: $blue;
  color: white;
  padding: 50px 0;

  .text {
    width: 100vw;
    margin: 0 auto;
    @include flex(column, space-between);

    p {
      padding: 50px 50px 0 50px;

      span {
        margin-left: 20px;
      }

      a {
        color: $orange;
      }
    }
  }
}

.store-container {
  background-color: white;
  @include flex(column, space-evenly);
  font-family: 'Mandali', sans-serif;
  padding: 50px 0;
  width: 100vw;

  .store {
    @include flex(column, space-evenly);
    width: 100vw;

    .store-content {
      padding: 0 50px;
      @include flex(row, space-between);
  
      img {
        width: 150px;
      }
  
      p {
        padding: 50px 0;
      }
    }
  }
}

.contact-container {
  background-color: white;
  @include flex(column, flex-start);
  font-family: 'Mandali', sans-serif;
  padding: 50px 0;
  width: 100vw;
  height: calc(100vh - 160px);

  .contact {
    width: 100vw;
    margin: 0 auto;
    @include flex(column, space-evenly);

    form {
      @include flex(column, space-between);
      padding-top: 30px;

      div {
        height: 60px;
        @include flex(column, space-between);
        
        input {
          font-family: 'Mandali', sans-serif;
          border: none;
          border-bottom: 2px solid $blue;
          width: 200px;
        }
      }

      textarea {
        font-family: 'Mandali', sans-serif;
        width: 300px;
        margin: 30px;
        min-height: 150px;
      }
    }
  }
}

.footer-container {
  background-color: $orange;
  font-family: 'Mandali', sans-serif;
  height: 65px;
  width: 100vw;
  
  .footer {
    height: 100%;
    width: 100vw;
    margin: 0 auto;
    padding: 0 20px;
    @include flex(row, space-between);

    h3, a {
      color: white;
    }

    div {
      width: 100px;
      @include flex(row, space-between);
    }

    img {
      width: 25px;
    }
  }
}

.faq {
  @include flex(column, space-evenly);

  .question {
    margin: 50px 20px 0 20px;
    @include flex(column, space-between);
    position: relative;
    text-align: center;

    h4 {
      @include flex(row, flex-start);
      max-width: 90vw;

      button {
        height: 35px;
        min-width: 35px;
        margin-right: 15px;
        font-weight: bold;
        background-color: $orange;
        border-radius: 50%;
        border: none;
        color: white;
        @include flex(column, center);
      }
  
      span {
        @include text;
      }
    }

    div {
      max-height: 0px;
      overflow:hidden;
      transition: all .75s ease;

      p {
        padding: 20px 20px 0 20px;
        white-space: pre-wrap;
      }
    }
  }
}

.team-container {
  @include flex(row, center);
  font-family: 'Mandali', sans-serif;
  width: 100vw;

  .team {
    @include flex(column, center);
    padding-bottom: 20px;
    text-align: center;

    .team-card {
      width: 350px;
      margin: 20px;
      padding: 20px;
      background-color: $grey;
      @include flex(column, space-between);
      box-shadow: 2px 2px 0 $purple,
       4px 4px 0 $blue,
       6px 6px 0 $green,
       8px 8px 0 $yellow,
       10px 10px 0 $orange,
       12px 12px 0 $red;

      h2 {
        margin: 10px;
      }

      .titles {
        @include flex(column, space-between);

        h4 {
          margin: 10px;
          color: $blue;
        }
      }

      a {
        margin: 10px;
      }

      img {
        height: 175px;
      }
    }
  }
}