@mixin flex($direction, $spacing) {
    display: flex;
    flex-direction: $direction;
    align-items: center;
    justify-content: $spacing;
}

@mixin heading($size) {
    font-size: $size;
    font-weight: bold;
    color: $orange;
}

@mixin text {
    font-size: 20px;
    line-height: 1.5;
}