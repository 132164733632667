@import './colors.scss', './mixins.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss"; 

@media screen and (min-width: 1000px) {
    .header-container {
        .header {
            width: 1000px;
        }
    }

    .hero {
        width: 1000px;

        .image-gallery {
            width: 1000px;
          }
        
          .image-gallery-slide img {
            height: 500px;
          }
    }

    .footer-container {
        .footer {
            width: 1000px;
        }
    }

    .text-container {
        .text {
            width: 800px;
        }
    }

    .store-container {
        .store {
            width: 1000px;
        }
    }

    .contact-container {
        .contact {
            width: 1000px;

            form {
                textarea {
                    width: 450px;
                }
            }
        }
    }
}