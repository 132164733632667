@import './colors.scss', './mixins.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss"; 

@media screen and (min-width: 700px) {
    #nav {
        @include flex(row, space-between);
        background-color: transparent;
        position: inherit;
        width: 175px;
    }

    .hero {
        padding: 30px 0;
            
        .image-gallery-slide img {
          height: 350px;
        }
      }

      #store-img {
          width: 200px;
      }

      #input-container {
          width: 450px;
          @include flex(row, space-between);
      }

    .team-container {
        .team {
            .team-card {
                width: 70vw;
                box-shadow: 3px 3px 0 $purple,
                6px 6px 0 $blue,
                9px 9px 0 $green,
                12px 12px 0 $yellow,
                15px 15px 0 $orange,
                18px 18px 0 $red;
      
                img {
                  height: 200px;
                }
            }
        }
    }
}